import { useState, useEffect } from "react"
import type { IEmployee, IQuarterly, IShift } from "../types"
import { getShifts } from "../API_shifts";
import { getAllQuarterDates } from "../API_quarters";
import { getEmployeeHours } from "../timing_utils";
import { getEmployees } from "../API_employee";

enum RankingMode {
    TOTAL = 'Total Hours',
    QUARTERLY = 'Quarter Hours',
    WEEKLY = 'Weekly Hours',
};

const TopHours = () => {
    const [searchName, setSearchName] = useState<string>('');
    const [rankingMode, setRankingMode] = useState<RankingMode>(RankingMode.TOTAL);

    const [shifts, setShifts] = useState<IShift[]>([]);
    useEffect(() => {
        let isMounted = true;
        getShifts().then(res => {
            if (!isMounted) {
                return;
            }
            setShifts(res.data.shifts ?? []);
        });
        return () => { isMounted = false; };
    }, []);

    const [quarterDates, setQuarterDates] = useState<IQuarterly[]>([]);
    useEffect(() => {
        let isMounted = true;
        getAllQuarterDates().then(res => {
            if (!isMounted) {
                return;
            }
            setQuarterDates(res.data ?? []);
        });
        return () => { isMounted = false; };
    }, []);

    const [employees, setEmployees] = useState<IEmployee[]>([]);
    const [filteredEmployees, setFilteredEmployees] = useState<IEmployee[]>([]);
    useEffect(() => {
        let isMounted = true;
        getEmployees().then(res => {
            if (!isMounted) {
                return;
            }
            setEmployees(res.data.employees ?? []);
        });
        return () => { isMounted = false; };
    }, []);
    useEffect(() => {
        setFilteredEmployees(employees.filter(
            employee => employee.lastName.toLowerCase().includes(searchName.toLowerCase())
        ));
    }, [searchName, employees]);

    const listEmployees = () => {
        const employeeHours = getEmployeeHours(filteredEmployees, shifts, quarterDates);

        // sort based on ranking mode
        let rankHoursMode: "totalHours" | "quarterlyHours" | "weeklyHours" = "totalHours";
        switch (rankingMode) {
            case RankingMode.TOTAL: {
                rankHoursMode = "totalHours";
                break;
            }
            case RankingMode.QUARTERLY: {
                rankHoursMode = "quarterlyHours";
                break;
            }
            case RankingMode.WEEKLY: {
                rankHoursMode = "weeklyHours";
                break;
            }
            default: {
                break;
            }
        }

        employeeHours.sort((a, b) => {
            if (a[rankHoursMode] !== b[rankHoursMode]) {
                return b[rankHoursMode] - a[rankHoursMode];
            }

            // then by department
            if (a.department !== b.department) {
                return a.department.localeCompare(b.department);
            }

            // then by name
            const aName = `${a.lastName}, ${a.firstName}`;
            const bName = `${b.lastName}, ${b.firstName}`;
            if (aName !== bName) {
                return aName.localeCompare(bName);
            }

            return 0;
        });

        const employeeHtml = employeeHours.map((employee, index) => {
            const employeeName = `${employee.lastName}, ${employee.firstName}`;
            return (
                <tr className="table-row" key={index}>
                    <td className="table-data">{employeeName}</td>
                    <td className="table-data">{employee.department}</td>
                    <td className="table-data">{employee.totalHours}</td>
                    <td className="table-data">{employee.quarterlyHours}</td>
                    <td className="table-data">{employee.weeklyHours}</td>
                </tr>
            );
        });

        return employeeHtml;
    };


    return (
        <div className="timesheet-container">
            <p className="timesheet-title">Top Hours by Person</p>
            <input
                type='text'
                onChange={e => setSearchName(e.target.value)}
                style={{
                    marginBottom: '16px',
                    padding: '4px'
                }}
                placeholder='Enter Last Name...'
            />
            <select value={rankingMode} onChange={e => setRankingMode(e.target.value as RankingMode)}>
                <option value={RankingMode.TOTAL}>Total Hours</option>
                <option value={RankingMode.QUARTERLY}>Quarter Hours</option>
                <option value={RankingMode.WEEKLY}>Weekly Hours</option>
            </select>
            <div className="timesheet">
                <table className="time-table">
                    <thead>
                        <tr>
                            <th className="table-headers">Team Member</th>
                            <th className="table-headers">Department</th>
                            <th
                                className="table-headers"
                                style={{
                                    textDecoration: `${rankingMode === RankingMode.TOTAL ? 'underline' : ''}`
                                }}
                            >
                                Total Hours
                            </th>
                            <th
                                className="table-headers"
                                style={{
                                    textDecoration: `${rankingMode === RankingMode.QUARTERLY ? 'underline' : ''}`
                                }}
                            >
                                Quarter Hours
                            </th>
                            <th
                                className="table-headers"
                                style={{
                                    textDecoration: `${rankingMode === RankingMode.WEEKLY ? 'underline' : ''}`
                                }}
                            >
                                Weekly Hours
                            </th>
                        </tr>
                    </thead>
                    <tbody>{listEmployees()}</tbody>
                </table>
            </div>
        </div>
    )
}

export default TopHours;
