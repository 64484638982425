import { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Login, { AuthLevel } from "./components/login";
import Navbar from "./components/navbar";
import Timesheet from "./components/timesheet";
import AdminPage from "./components/admin_page";
import ManageDepartments from "./components/manage_departments";
import TopHours from "./components/top_hours";
import DepartmentHours from "./components/department_hours";
import AddUser from "./components/add_user";
import Shifts from "./components/shifts";
import EditQuarter from "./components/edit_quarter";

function App() {

    const [token, setToken] = useState<AuthLevel>(AuthLevel.NONE); //This is set so that you can see the site
    const handleLogout = () => {
        setToken(AuthLevel.NONE); // Clear the token when logging out
    };

    if (token === AuthLevel.NONE) {
        return (
            <BrowserRouter>
                <div className="App">
                    <Login setToken={setToken} />
                </div>
            </BrowserRouter>
        );
    }

    return (
        <BrowserRouter>
            <div className="App">
                <Navbar authLevel={token} onLogout={handleLogout} />
                <Routes>
                    <Route path="/login" element={<Login setToken={setToken} />} />
                    <Route path="/" element={<Timesheet authLevel={token} />} />
                    <Route path="/timesheet" element={<Timesheet authLevel={token} />} />
                    <Route path="/shifts" element={<Shifts />} />
                    <Route path="/admin_page" element={<AdminPage />} />
                    <Route path="/manage-departments" element={<ManageDepartments />} />
                    <Route path="/top-hours" element={<TopHours />} />
                    <Route path="/department-hours" element={<DepartmentHours />} />
                    <Route path="/add-user-page" element={<AddUser />} />
                    <Route path="/edit-quarter" element={<EditQuarter />} />
                </Routes>
            </div>
        </BrowserRouter>
    );
}

export default App;
