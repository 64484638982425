import { clockIn, clockOut } from "../API_employee";
import type { IEmployee } from "../types";

interface ClockModalProps {
    setModal: Function;
    employee: IEmployee;
    setEmployee: React.Dispatch<React.SetStateAction<IEmployee>>;
}

const ClockModal: React.FC<ClockModalProps> = (props: ClockModalProps) => {
    const employee_name = `${props.employee.firstName} ${props.employee.lastName}`;

    const setStatus = (): void => {
        const clock_function = props.employee.isClockedIn
            ? clockOut
            : clockIn
        props.employee.isClockedIn = !props.employee.isClockedIn;
        clock_function(props.employee).then(() => {
            props.setModal(false);
            props.setEmployee(employee => {
                const newEmployee = {...employee};
                newEmployee.isClockedIn = !newEmployee.isClockedIn;
                return newEmployee;
            })
        })
    };

    const buttonAction: string = props.employee.isClockedIn
        ? "Clock Out"
        : "Clock In"

    return (
        <div className="modal-background">
            <div className="clock-modal">
                <div className="close-modal">
                    <button
                        onClick={() => {
                            props.setModal(false);
                        }}
                    >
                        X
                    </button>
                </div>
                <div className="modal-title">
                    <h1>Please confirm your choice</h1>
                </div>
                <div className="modal-body">
                    <p>
                        {buttonAction} {employee_name}
                    </p>
                </div>
                <div className="modal-footer">
                    <button
                        className="modal-cancel"
                        onClick={() => {
                            props.setModal(false);
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        className="modal-confirm"
                        onClick={() => {
                            setStatus();
                        }}
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ClockModal;
