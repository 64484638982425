import { useState } from "react";
import { IDepartment } from "../types";
import { deleteEmployee, getEmployees, updateEmployee } from "../API_employee";
import { editDepartment, deleteDepartment } from "../API_department";

interface EditModalProps {
    setModal: Function;
    department: IDepartment
}

const EditDepartmentModal = (props: EditModalProps) => {
    const department_name = props.department.name;
    const [department_data, setDepartmentData] = useState<IDepartment>({...props.department});

    const confirmEdit = (): void => {
        const edited = editDepartment(props.department, department_data)

        const updated_employees = getEmployees()
            .then((response) => {
                const all_employees = response.data.employees
                const update_promises = all_employees
                    .filter(employee => employee.department === props.department.name)
                    .map(
                        employee => updateEmployee({...employee, department: department_data.name})
                    )
                Promise.all(update_promises)
            })
            .catch((error) => console.log(error))

        Promise.all([edited, updated_employees])
            .then(() => props.setModal(false))
            .catch((err) => console.log(err))
    };

    const confirmDelete = () => {
        const deleted = deleteDepartment(department_data.name)

        const updated_employees = getEmployees()
            .then((response) => {
                const all_employees = response.data.employees
                const update_promises = all_employees
                    .filter(employee => employee.department === department_data.name)
                    .map(
                        employee => deleteEmployee(employee.firstName, employee.lastName)
                    )
                Promise.all(update_promises)
            })
            .catch((error) => console.log(error))

        Promise.all([deleted, updated_employees])
            .then(() => props.setModal(false))
            .catch((err) => console.log(err))
    }

    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
        setDepartmentData({
            ...department_data,
            [e.currentTarget.id]: e.currentTarget.value
        })
    }

    const editForm = () => {
        return (
            <div>
                <div>
                    <label>New Name</label>
                    <input
                        type="text"
                        id="name"
                        onChange={e => handleChange(e)}
                    />
                </div>
                <button className="delete-button" onClick={() => confirmDelete()}>
                    {`Remove ${department_name}`}
                </button>
            </div>
        )
    }

    return (
        <div className="modal-background">
            <div className="clock-modal">
                <div className="close-modal">
                    <button onClick={() => props.setModal(false)}>
                        X
                    </button>
                </div>
                <div className="modal-title">
                    <h1>{`Editing ${department_name}...`}</h1>
                </div>
                <div className="modal-body">
                    {editForm()}
                </div>
                <div className="modal-footer">
                    <button
                        className="modal-cancel"
                        onClick={() => {
                            props.setModal(false);
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        className="modal-confirm"
                        onClick={() => {
                            confirmEdit();
                        }}
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    );
}

export default EditDepartmentModal
