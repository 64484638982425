import { IEmployee, IQuarterly, IShift } from "./types";

export function getCurrentQuarter(quarterDates: IQuarterly[]) {
    const today = new Date();

    let currentQuarter = null
    if (quarterDates.length === 0) {
        return currentQuarter;
    }

    // NOTE: quarters come from the server sorted earliest->latest
    for (const quarter of quarterDates) {
        const quarterDate = new Date(quarter.quarterDate);
        if (quarterDate.getTime() > today.getTime()) {
            break;
        }
        currentQuarter = quarter;
    }

    return currentQuarter;
}

export function getWeeklyShifts(shifts: IShift[]) {
    const weeklyShifts: IShift[] = [];
    const today = new Date();

    // week goes from Sunday->Saturday
    const weekStart = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());

    // 7 days, 24 hr/day, 60 min/hr, 60 sec/min, 1000 ms/sec
    // -1 so it's not exactly the next sunday
    const weekEnd = new Date((weekStart.getTime() + 7 * 24 * 60 * 60 * 1000) - 1);

    for (const shift of shifts) {
        const shiftDate = new Date(shift.clockOutTime);
        const inWeek = shiftDate.getTime() >= weekStart.getTime() && shiftDate.getTime() <= weekEnd.getTime();

        if (inWeek) {
            weeklyShifts.push(shift);
        }
    }

    return weeklyShifts;
}

/**
 * NOTE: pass in the current quarter
 * The logic in the function gets all the shifts after the quarter date
 * This is correct so long as the quarter passed is always the current quarter
 */
export function getQuarterlyShifts(shifts: IShift[], quarter: IQuarterly) {
    const quarterlyShifts: IShift[] = [];
    const quarterDate = new Date(quarter.quarterDate).getTime();

    for (const shift of shifts) {
        const shiftDate = new Date(shift.clockOutTime);
        const inQuarter = shiftDate.getTime() >= quarterDate;

        if (inQuarter) {
            quarterlyShifts.push(shift);
        }
    }

    return quarterlyShifts;
}

export function getShiftHours(shifts: IShift[]) {
    if (shifts.length === 0) {
        return 0;
    }
    let shiftHours = 0;
    for (const shift of shifts) {
        if (shift.autoClockedOut) {
            continue;
        }
        const shiftStart = new Date(shift.clockInTime);
        const shiftEnd = new Date(shift.clockOutTime);

        // 60 min/hr, 60 sec/min, 1000 ms/sec
        const hours = (shiftEnd.getTime() - shiftStart.getTime()) / (60 * 60 * 1000);

        shiftHours += hours;
    }

    return shiftHours
}

export function getEmployeeHours(employees: IEmployee[], shifts: IShift[], quarterDates: IQuarterly[]) {
    const employeeHours = [];
    for (const employee of employees) {
        const employeeShifts = shifts.filter(shift => {
            const shiftFirstName = shift.firstName;
            const shiftLastName = shift.lastName;

            return shiftFirstName === employee.firstName && shiftLastName === employee.lastName;
        });

        const currentQuarter = getCurrentQuarter(quarterDates);
        if (!currentQuarter) {
            break;
        }

        const employeeWeeklyShifts = getWeeklyShifts(employeeShifts);
        const employeeQuarterlyShifts = getQuarterlyShifts(employeeShifts, currentQuarter);

        const totalHours = parseFloat(getShiftHours(employeeShifts).toFixed(3));
        const quarterlyHours = parseFloat(getShiftHours(employeeQuarterlyShifts).toFixed(3));
        const weeklyHours = parseFloat(getShiftHours(employeeWeeklyShifts).toFixed(3));

        const department = employee.department;

        employeeHours.push(
            {
                firstName: employee.firstName,
                lastName: employee.lastName,
                department: department,
                totalHours: totalHours,
                quarterlyHours: quarterlyHours,
                weeklyHours: weeklyHours
            }
        );
    }

    return employeeHours;
}