import axios, { AxiosResponse } from "axios";
import { ApiDataType, DepartmentRankingApiType } from "./types";

const baseUrl: string = "https://time.bruinlife.com/api";

// add calling for all employee backend functions
export const getTotalHoursRanking = async (): Promise<
  AxiosResponse<ApiDataType>
> => {
    try {
        const ranking: AxiosResponse<ApiDataType> = await axios.get(
            baseUrl + "/get-total-hours-ranking"
        );
        return ranking;
    } catch (error) {
        throw new Error();
    }
};

export const getWeeklyHoursRanking = async (): Promise<
  AxiosResponse<ApiDataType>
> => {
    try {
        const ranking: AxiosResponse<ApiDataType> = await axios.get(
            baseUrl + "/get-weekly-hours-ranking"
        );
        return ranking;
    } catch (error) {
        throw new Error();
    }
};

export const getQuarterHoursRanking = async (): Promise<
  AxiosResponse<ApiDataType>
> => {
    try {
        const ranking: AxiosResponse<ApiDataType> = await axios.get(
            baseUrl + "/get-quarter-hours-ranking"
        );
        return ranking;
    } catch (error) {
        throw new Error();
    }
};


export const getDepartmentRanking = async (mode: string): Promise<
  AxiosResponse<DepartmentRankingApiType>
> => {
    try {
        const departmentRanking: AxiosResponse<DepartmentRankingApiType> = await axios.get(
            baseUrl + `/get-department-${mode}-ranking`
        );
        return departmentRanking;
    } catch (error) {
        console.log(error)
        throw new Error();
    }
};
