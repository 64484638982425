import { useState, useEffect } from "react"
import { getDepartmentRanking } from "../API_ranking"
import { DepartmentRankingType } from "../types";

enum RankingMode {
    TOTAL = "Total Hours",
    QUARTERLY = "Quarterly Hours",
    WEEKLY = "Weekly Hours",
}

const strToMode = (mode_string: string): RankingMode => {
    // defaults to total

    switch(mode_string) {
        case "Quarterly Hours": return RankingMode.QUARTERLY;
        case "Weekly Hours": return RankingMode.WEEKLY;
        default: return RankingMode.TOTAL;
    }
}

const modeToAPIStr = (mode: RankingMode): string => {
    switch(mode) {
        case RankingMode.QUARTERLY: return "quarter";
        case RankingMode.WEEKLY: return "weekly";
        default: return "total";
    }
}

const DepartmentHours = () => {
    const [departments, setDepartments] = useState<DepartmentRankingType[]>([]);
    const [ranking_mode, setRankingMode] = useState<RankingMode>(RankingMode.TOTAL)

    useEffect(() => {
        getDepartmentRanking(modeToAPIStr(RankingMode.TOTAL)).then(response => {
            setDepartments(response.data.departments)
        })
    }, [])

    useEffect(() => {
        getDepartmentRanking(modeToAPIStr(ranking_mode)).then(
            response => setDepartments(response.data.departments)
        )
    }, [ranking_mode])

    const listDepartments = () => {
        return departments.map((department, index) => {
            return (
                <tr className="table-row" key={index}>
                    <td className="table-data">
                        {department._id}
                    </td>
                    <td className="table-data">
                        {department.averageHours.toFixed(2)}
                    </td>
                </tr>
            )
        })
    }

    return (
        <div className="timesheet-container">
            <p className="timesheet-title">Top Hours by Department</p>
            <select
                value={ranking_mode}
                onChange={e => setRankingMode(strToMode(e.target.value))}
            >
                <option value={RankingMode.TOTAL}>{RankingMode.TOTAL}</option>
                <option value={RankingMode.QUARTERLY}>{RankingMode.QUARTERLY}</option>
                <option value={RankingMode.WEEKLY}>{RankingMode.WEEKLY}</option>
            </select>
            <div className="timesheet">
                <table className="time-table">
                    <thead>
                        <tr>
                        <th className="table-headers">Department</th>
                        <th className="table-headers">{ranking_mode}</th>
                        </tr>
                    </thead>
                    <tbody>{listDepartments()}</tbody>
                </table>
            </div>
        </div>
    )
}

export default DepartmentHours
