import axios, { AxiosResponse } from "axios";
import { ApiDataType, IEmployee } from "./types";

const baseUrl: string = "https://time.bruinlife.com/api";

export const getEmployees = async (): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const employees: AxiosResponse<ApiDataType> = await axios.get(
            baseUrl + "/employees"
        );
        // console.log("fetch employees");
        return employees;
    } catch (error) {
        throw new Error();
    }
};

export const getEmployee = async (
    formData: IEmployee
): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const queriedEmployee: AxiosResponse<ApiDataType> = await axios.get(
            `${baseUrl}/getemployee?firstName=${formData.firstName}&lastName=${formData.lastName}`
        );
        return queriedEmployee;
    } catch (error) {
        throw new Error();
    }
};

export const clockIn = async (
    formData: IEmployee
): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const clockedInEmployee: AxiosResponse<ApiDataType> = await axios.put(
            `${baseUrl}/clock-in?firstName=${formData.firstName}&lastName=${formData.lastName}`
        )
        return clockedInEmployee;
    } catch (error) {
        throw new Error();
    }
};

export const clockOut = async (
    formData: IEmployee
): Promise<AxiosResponse<ApiDataType>> => {
    const clockedOutEmployee: AxiosResponse<ApiDataType> = await axios.put(
        `${baseUrl}/clock-out?firstName=${formData.firstName}&lastName=${formData.lastName}`
    )
    return clockedOutEmployee;
};

export const addEmployee = async (
    formData: IEmployee
): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const employee: Pick<
            IEmployee,
            | "firstName"
            | "lastName"
            | "department"
            | "weeklyHours"
            | "quarterHours"
            | "totalHours"
            | "isClockedIn"
        > = {
            firstName: formData.firstName,
            lastName: formData.lastName,
            department: formData.department,
            weeklyHours: formData.weeklyHours,
            quarterHours: formData.quarterHours,
            totalHours: formData.totalHours,
            isClockedIn: formData.isClockedIn,
        };
        const saveEmployee: AxiosResponse<ApiDataType> = await axios.post(
            baseUrl + "/add-employee",
            employee
        );
        return saveEmployee;
    } catch (error) {
        throw new Error();
    }
};

export const updateEmployee = async (
    formData: IEmployee
): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const employeeUpdate: Pick<
            IEmployee,
            | "firstName"
            | "lastName"
            | "department"
            | "weeklyHours"
            | "quarterHours"
            | "totalHours"
            | "isClockedIn"
        > = {
            firstName: formData.firstName,
            lastName: formData.lastName,
            department: formData.department,
            weeklyHours: formData.weeklyHours,
            quarterHours: formData.quarterHours,
            totalHours: formData.totalHours,
            isClockedIn: formData.isClockedIn,
        };

        const updatedEmployee: AxiosResponse<ApiDataType> = await axios.put(
            `${baseUrl}/edit-employee?firstName=${formData.firstName}&lastName=${formData.lastName}`,
            employeeUpdate
        );
        return updatedEmployee;
    } catch (error) {
        throw new Error();
    }
};

export const deleteEmployee = async (
    firstName: string,
    lastName: string,
): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const deletedEmployee: AxiosResponse<ApiDataType> = await axios.delete(
            baseUrl + "/delete-employee",
            { 
                data: {
                    firstName: firstName,
                    lastName: lastName,
                }
            }
        );
        return deletedEmployee;
    } catch (error) {
        throw new Error();
    }
};
