import axios, { AxiosResponse } from "axios";
import { IShift, ShiftsApiType } from "./types";

const baseUrl: string = "https://time.bruinlife.com/api";

export async function getShifts(): Promise<AxiosResponse<ShiftsApiType>> {
    const shifts: AxiosResponse<ShiftsApiType> = await axios.get(
        baseUrl + "/shifts"
    );
    return shifts;
}

export async function getShift(firstName: string, lastName: string): Promise<AxiosResponse<ShiftsApiType>> {
    const shift: AxiosResponse<ShiftsApiType> = await axios.get(
        `${baseUrl}/shift/${firstName}/${lastName}`
    );
    return shift;
}

export async function addShift(shift: IShift): Promise<AxiosResponse<ShiftsApiType>> {
    const bodyShift: Pick<IShift,
        | 'firstName'
        | 'lastName'
        | 'clockInTime'
        | 'clockOutTime'
        | 'shiftHours'
        | 'autoClockedOut'
    > = {
        firstName: shift.firstName,
        lastName: shift.lastName,
        clockInTime: shift.clockInTime,
        clockOutTime: shift.clockOutTime,
        shiftHours: shift.shiftHours,
        autoClockedOut: shift.autoClockedOut
    };

    const addedShift: AxiosResponse<ShiftsApiType> = await axios.post(
        baseUrl + "/shift/add",
        bodyShift
    );
    return addedShift;
}

export async function editShift(shift: IShift): Promise<AxiosResponse<ShiftsApiType>> {
    const bodyShift: Pick<IShift,
        | 'firstName'
        | 'lastName'
        | 'clockInTime'
        | 'clockOutTime'
        | 'shiftHours'
        | 'autoClockedOut'
    > = {
        firstName: shift.firstName,
        lastName: shift.lastName,
        clockInTime: shift.clockInTime,
        clockOutTime: shift.clockOutTime,
        shiftHours: shift.shiftHours,
        autoClockedOut: shift.autoClockedOut
    };

    const editedShift: AxiosResponse<ShiftsApiType> = await axios.put(
        baseUrl + "/shift/edit/" + shift._id,
        bodyShift
    );
    return editedShift;
}

export async function deleteShift(shift: IShift): Promise<AxiosResponse<ShiftsApiType>> {
    const deletedShift: AxiosResponse<ShiftsApiType> = await axios.delete(
        baseUrl + "/shift/delete/" + shift._id
    )

    return deletedShift;
}
