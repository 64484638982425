import React, { useEffect, useState } from "react";
import type { IEmployee, IDepartment } from "../types";
import { getDepartments } from "../API_department";

export interface FilterModes {
    department: boolean;
    name: boolean;
}

interface FilterProps {
    stored_employees: IEmployee[];
    filter_fields?: FilterModes;
    updateEmployees: (value: IEmployee[]) => void
}

enum Mode {
    NONE = "None",
    DEPARTMENT = "Department",
    NAME = "Name",
}

const FILTER_PLACEHOLDER = "Filter By...";

const strToMode = (mode_string: string): Mode => {
    switch(mode_string) {
        case "Department": return Mode.DEPARTMENT;
        case "Name": return Mode.NAME;
        default: return Mode.NONE;
    }
}

const Filter: React.FC<FilterProps> = ({stored_employees, updateEmployees, filter_fields}) => {

    const [current_mode, setCurrentMode] = useState<Mode>(Mode.NONE);
    const [filter_input, setFilterInput] = useState<string>("");
    const [filtered_employees, setFilteredEmployees] = useState<IEmployee[]>(stored_employees);
    const [all_departments, setAllDepartments] = useState<IDepartment[]>([])

    useEffect(() => {
        getDepartments().then((response) => {
            setAllDepartments(response.data.departments)
        })
    }, [])

    // get the selected filter_fields
    const getSelectedFilters = (): Mode[] => {
        const modes: Mode[] = []
        if (filter_fields?.department) modes.push(Mode.DEPARTMENT);
        if (filter_fields?.name) modes.push(Mode.NAME);

        // if filter_fields ended up being undefined, just push all
        if (modes.length === 0) {
            modes.push(Mode.DEPARTMENT);
            modes.push(Mode.NAME);
        }
        return modes;
    }

    // input JSX
    const createFilterSelector = (filter_type: string, options: string[]) => {
        return (
            <select
                value={filter_input}
                onChange={e => setFilterInput(e.target.value)}
            >
                <option value="" disabled>Filter by {filter_type}...</option>
                {
                    options.map((option, index) =>
                        <option value={option} key={index}>
                            {option}
                        </option>
                    )
                }
                <option value="">None</option>
            </select>
        )
    }

    const createFilterSearch = (filter_type: string) => {
        return (
            <input
                type="text"
                placeholder={`Filter By ${filter_type}...`}
                value = {filter_input}
                onChange={e => setFilterInput(e.target.value)}
            />
        )
    }

    // reset the filter value whenever the current filter changes
    useEffect(() => {
        setFilterInput("");
    }, [current_mode]);

    // change the filtered employees when the filter input changes
    useEffect(() => {
        // filtering functions
        const filterDepartment = (): IEmployee[] => {
            return stored_employees.filter(employee => {
                if (filter_input === "") return true;
                return employee.department === filter_input;
            })
        }

        const filterName = (): IEmployee[] => {
            return stored_employees.filter(employee => {
                const full_name = `${employee.firstName} ${employee.lastName}`.toLowerCase();
                const normalized_filter = filter_input.toLowerCase();
                return full_name.startsWith(normalized_filter);
            })
        }

        switch (current_mode) {
            case Mode.DEPARTMENT: {
                setFilteredEmployees(filterDepartment());
                break;
            }
            case Mode.NAME: {
                setFilteredEmployees(filterName());
                break
            }
            // default is no filter
            default:
                setFilteredEmployees(stored_employees);
        }
    }, [current_mode, filter_input, stored_employees])

    // update the employees whenever the filtered employees change
    useEffect(() => {
        updateEmployees(filtered_employees)
    }, [updateEmployees, filtered_employees])

    const getDepartmentOptions = () => {
        const main_options = all_departments.map(department => department.name)

        if (stored_employees.some(employee => employee.department === "[DELETED]")) {
            return main_options.concat(["[DELETED]"]);
        }

        return main_options
    }

    const getFilterSelector = () => {
        return (
            <select
                value={current_mode === Mode.NONE ? "" : current_mode}
                onChange={e => setCurrentMode(strToMode(e.target.value))}
            >
                <option value="" disabled>{FILTER_PLACEHOLDER}</option>
                    {
                        getSelectedFilters().map((available_filter, i) =>
                            <option value={available_filter} key={i}>
                                {available_filter}
                            </option>
                        )
                    }
                <option value="">None</option>
            </select>
        )
    }

    const getFilterInput = () => {
        switch (current_mode) {
            case Mode.DEPARTMENT: return createFilterSelector(Mode.DEPARTMENT, getDepartmentOptions());
            case Mode.NAME: return createFilterSearch(Mode.NAME);
            // default is no filter
            default: return (<></>)
        }
    }

    return (
        <div className="timesheet-search">
            {getFilterSelector()}
            {getFilterInput()}
        </div>
    )
}

export default Filter;
