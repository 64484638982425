import { useState, useEffect } from "react";
import { IEmployee, IDepartment } from "../types";
import { addEmployee } from "../API_employee";
import { getDepartments } from "../API_department";

interface AddModalProps {
    setModal: Function;
}

const AddEmployeeModal = (props: AddModalProps) => {
    const [employee_data, setEmployeeData] = useState<IEmployee>(Object);
    const [all_departments, setAllDepartments] = useState<IDepartment[]>([])

    useEffect(() => {
        getDepartments().then((response) => {
            setAllDepartments(response.data.departments)
        })
    }, [])

    const isFormValid = () => {
        // Check if any of the fields is empty
        return employee_data.firstName && employee_data.lastName && employee_data.department;
    };

    const confirmAdd = (): void => {
        addEmployee(employee_data)
            .then(() => props.setModal(false))
            .catch((error) => console.log(error));
    };

    const handleChange = (
        e: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLSelectElement>
    ) => {
        setEmployeeData({
            ...employee_data,
            [e.currentTarget.id]: e.currentTarget.value
        })
        // console.log(employee_data)
    }

    const getDepartmentOptions = () => {
        return (
            <select id="department" onChange={e => handleChange(e)}>
                <option value="" disabled selected hidden>Pick a Department...</option>
                {all_departments
                    .map((department, index) =>
                        <option value={department.name} key={index}>
                            {department.name}
                        </option>
                    )
                }
            </select>
        )
    }

    const addForm = () => {
        return (
            <div>
                <div className="modal-field">
                    <label>First Name</label>
                    <input
                        type="text"
                        id="firstName"
                        onChange={handleChange}
                    />
                </div>
                <div className="modal-field">
                    <label>Last Name</label>
                    <input
                        type="text"
                        id="lastName"
                        onChange={handleChange}
                    />
                </div>
                <div className="modal-field">
                    <label>Department</label>
                    {getDepartmentOptions()}
                </div>
            </div>
        )
    }

    return (
        <div className="modal-background">
            <div className="clock-modal">
                <div className="close-modal">
                    <button onClick={() => props.setModal(false)}>
                        x
                    </button>
                </div>
                <div className="modal-title">
                    <h1>Add employee</h1>
                </div>
                <div className="modal-body">
                    {addForm()}
                </div>
                <div className="modal-footer">
                    <button
                        className="modal-cancel"
                        onClick={() => {
                            props.setModal(false);
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        className="modal-confirm"
                        onClick={() => {
                            if (isFormValid()) {
                                confirmAdd();
                            } else {
                                console.log("Please fill out all fields before confirming.");
                            }
                        }}
                        style={{ backgroundColor: isFormValid() ? "" : "gray", 
                                cursor: isFormValid() ? "pointer" : "not-allowed",}}
                        disabled={!isFormValid()}
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    );
}

export default AddEmployeeModal
