import { AxiosResponse } from "axios";
import { useState, useEffect } from "react"
import { getQuarterHoursRanking, getTotalHoursRanking, getWeeklyHoursRanking } from "../API_ranking";
import type { ApiDataType, IEmployee } from "../types"

enum RankingMode {
    TOTAL = 'Total Hours',
    QUARTERLY = 'Quarter Hours',
    WEEKLY = 'Weekly Hours',
};

const TopHours = () => {
    const [storedEmployees, setStoredEmployees] = useState<IEmployee[]>([]);
    const [filtered_employees, setFilteredEmployees] = useState<IEmployee[]>([]);
    const [searchName, setSearchName] = useState<string>('');
    const [rankingMode, setRankingMode] = useState<RankingMode>(RankingMode.TOTAL);

    useEffect(() => {
        let rankingAPI: () => Promise<AxiosResponse<ApiDataType>> = getTotalHoursRanking;
        switch (rankingMode) {
            case RankingMode.TOTAL: 
                rankingAPI = getTotalHoursRanking;
                break;
            case RankingMode.QUARTERLY:
                rankingAPI = getQuarterHoursRanking;
                break;
            case RankingMode.WEEKLY:
                rankingAPI = getWeeklyHoursRanking;
                break;
            default:
                rankingAPI = getTotalHoursRanking;
        }
        rankingAPI().then(response => {
            setStoredEmployees(response.data.employees);
            setFilteredEmployees(response.data.employees);
        })
    }, [rankingMode]);

    const listEmployees = () => {
        const employee_list = [];

        for (let i = 0; i < filtered_employees.length; i++) {
            let employee = filtered_employees[i];
            let employee_name = `${employee.lastName}, ${employee.firstName}`;

            employee_list.push(
                <tr className="table-row" key={i}>
                    <td className='table-data'>{employee_name}</td>
                    <td className='table-data'>{employee.department}</td>
                    <td className='table-data'>{employee.totalHours.toFixed(3)}</td>
                    <td className='table-data'>{employee.quarterHours.toFixed(3)}</td>
                    <td className='table-data'>{employee.weeklyHours.toFixed(3)}</td>
                </tr>
            );
        }

        return employee_list;
    };

    useEffect(() => {
        setFilteredEmployees(storedEmployees.filter(
            (e: IEmployee) => {
                return e.lastName.toLowerCase().startsWith(searchName.toLowerCase());
            } 
        ));
    }, [searchName, storedEmployees]);

    return (
        <div className="timesheet-container">
            <p className="timesheet-title">Top Hours by Person</p>
            <input
                type='text'
                onChange={e => setSearchName(e.target.value)}
                style={{
                    marginBottom: '16px',
                    padding: '4px'
                }}
                placeholder='Enter Last Name...'
            />
            <select value={rankingMode} onChange={e => setRankingMode(e.target.value as RankingMode)}>
                <option value={RankingMode.TOTAL}>Total Hours</option>
                <option value={RankingMode.QUARTERLY}>Quarter Hours</option>
                <option value={RankingMode.WEEKLY}>Weekly Hours</option>
            </select>
            <div className="timesheet">
                <table className="time-table">
                    <thead>
                        <tr>
                            <th className="table-headers">Team Member</th>
                            <th className="table-headers">Department</th>
                            <th
                                className="table-headers"
                                style={{
                                    textDecoration: `${rankingMode === RankingMode.TOTAL ? 'underline' : ''}` 
                                }}
                            >
                                Total Hours
                            </th>
                            <th 
                                className="table-headers"
                                style={{
                                    textDecoration: `${rankingMode === RankingMode.QUARTERLY ? 'underline' : ''}` 
                                }}
                            >
                                Quarter Hours
                            </th>
                            <th 
                                className="table-headers"
                                style={{
                                    textDecoration: `${rankingMode === RankingMode.WEEKLY ? 'underline' : ''}` 
                                }}
                            >
                                Weekly Hours
                            </th>
                        </tr>
                    </thead>
                    <tbody>{listEmployees()}</tbody>
                </table>
            </div>
        </div>
    )
}

export default TopHours
