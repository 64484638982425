import { useState } from "react";
import { addQuarterDate, updateQuarterDate } from "../API_quarters";
import { IQuarterly } from "../types";

interface AddModalProps {
    setModal: Function;
    currentQuarters: IQuarterly[];
}

const AddQuarterModal = (props: AddModalProps) => {
    const [quarter, setQuarter] = useState<IQuarterly>(Object);

    function confirmAdd(): void {
        const addingQuarter = { ...quarter }
        addingQuarter.year = quarter.quarterDate.getFullYear();

        const quarterExists = props.currentQuarters.some(q => {
            return q.quarter === addingQuarter.quarter && q.year === addingQuarter.year;
        });

        if (quarterExists) {
            updateQuarterDate(addingQuarter).then(() => {
                props.setModal(false);
            })
        } else {
            addQuarterDate(addingQuarter).then(() => {
                props.setModal(false);
            });
        }
    }

    function dateAsValue(date: Date): string {
        // because midnight times get pushed 1 day back
        date.setUTCHours(8);
        let year = date.getFullYear().toString();
        let month = (date.getMonth() + 1).toString(); // month indexes from 0
        let day = date.getDate().toString();

        if (month.length < 2) {
            month = '0' + month;
        } 
        if (day.length < 2) {
            day = '0' + day;
        }

        return `${year}-${month}-${day}`;
    }

    function addForm() {
        return (
            <div>
                <div className="modal-field">
                    <label>Quarter</label>
                    <select
                        value={quarter.quarter ?? 'None'}
                        onChange={e => setQuarter(q => {
                            const newQ = { ...q };
                            newQ.quarter = e.target.value;
                            return newQ;
                        })}
                    >
                        <option value='Fall'>Fall</option>
                        <option value='Winter'>Winter</option>
                        <option value='Spring'>Spring</option>
                        <option value='None' disabled>None</option>
                    </select>
                    <label>Start Date</label>
                    <input
                        type="date"
                        value={dateAsValue(quarter.quarterDate ?? new Date())}
                        onChange={e => setQuarter(q => {
                            const newQ = { ...q };
                            newQ.quarterDate = new Date(e.target.value);
                            return newQ;
                        })}
                    />
                </div>
            </div>
        )
    }

    return (
        <div className="modal-background">
            <div className="clock-modal">
                <div className="close-modal">
                    <button onClick={() => props.setModal(false)}>
                        X
                    </button>
                </div>
                <div className="modal-title">
                    <h1>{`Adding New Quarter Start Date`}</h1>
                </div>
                <div className="modal-body">
                    {addForm()}
                </div>
                <div className="modal-footer">
                    <button
                        className="modal-cancel"
                        onClick={() => {
                            props.setModal(false);
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        className="modal-confirm"
                        onClick={() => confirmAdd()}
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    );
}

export default AddQuarterModal;
