import { useEffect, useState } from "react";
import { addUser } from "../API_auth";
import { IAuth } from "../types";
import { AuthLevel } from "./login";

enum StatusMessage {
    WRONG_PASSWORD = "Passwords Don't Match",
    DUPLICATE_USER = "User Already Exists",
    ADDED_USER = "Successfully Added User",
    MISSING_USERNAME = "Missing Username",
    MISSING_PASSWORD = "Missing Password",
    FAILED_ADD = "Failed to Add User",
    NONE = ""
}

const AddUser = () => {
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [confirm_password, setConfirmPassword] = useState<string>("");
    const [authLevel, setAuthLevel] = useState<AuthLevel>(AuthLevel.READ_ONLY);

    const [show_password, setShowPassword] = useState<boolean>(false);
    const [show_confirm_password, setShowConfirmPassword] = useState<boolean>(false);

    const [status, setStatus] = useState<StatusMessage>(StatusMessage.NONE);

    useEffect(() => {
        if (status !== StatusMessage.NONE) {
            const timer = setTimeout(() => {
                setStatus(StatusMessage.NONE)
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [status])

    const confirmFieldsFilled = () => {
        if (username === "") {
            setStatus(StatusMessage.MISSING_USERNAME)
            return false
        }
        if (password === "") {
            setStatus(StatusMessage.MISSING_PASSWORD)
            return false
        }
        if (password !== confirm_password) {
            setStatus(StatusMessage.WRONG_PASSWORD)
            return false
        }
        return true
    }

    const resetForm = () => {
        setUsername("")
        setPassword("")
        setConfirmPassword("")
        setAuthLevel(AuthLevel.READ_ONLY)
        setShowPassword(false)
        setShowConfirmPassword(false)
    }

    const submitUser = () => {
        if (!confirmFieldsFilled()) {
            return
        }
        const new_user = {} as IAuth
        new_user.username = username;
        new_user.password = password;
        new_user.authLevel = authLevel;

        addUser(new_user)
            .then(_ => setStatus(StatusMessage.ADDED_USER))
            .catch(err => {
                switch (err.response.status) {
                    case 404: {
                        setStatus(StatusMessage.DUPLICATE_USER)
                        break
                    }
                    default: {
                        setStatus(StatusMessage.FAILED_ADD)
                    }
                }
            })

        resetForm()
    }

    return (
        <div className="timesheet-container">
            <p className="timesheet-title">Add New User/Admin Account</p>
            <div className="add-user-form">
                <label>
                    Username:
                    <input
                        type="text"
                        value={username}
                        onChange={e => setUsername(e.target.value)}
                    />
                </label>
                <label>
                    Password:
                    <input
                        type={show_password ? "text" : "password"}
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                </label>
                <label>
                    Show Password:
                    <input
                        type="checkbox"
                        checked={show_password}
                        onChange={() => setShowPassword(!show_password)}
                    />
                </label>
                <label>
                    Confirm Password:
                    <input
                        type={show_confirm_password ? "text" : "password"}
                        value={confirm_password}
                        onChange={e => setConfirmPassword(e.target.value)}
                    />
                </label>
                <label>
                    Show Confirm Password:
                    <input
                        type="checkbox"
                        checked={show_confirm_password}
                        onChange={() => setShowConfirmPassword(!show_confirm_password)}
                    />
                </label>
                <label>
                    Read Only
                    <input
                        type='radio'
                        value={AuthLevel.READ_ONLY}
                        checked={authLevel === AuthLevel.READ_ONLY}
                        onChange={e => setAuthLevel(parseInt(e.target.value) as AuthLevel)}
                    />
                </label>
                <label>
                    Read Write
                    <input
                        type='radio'
                        value={AuthLevel.READ_WRITE}
                        checked={authLevel === AuthLevel.READ_WRITE}
                        onChange={e => setAuthLevel(parseInt(e.target.value) as AuthLevel)}
                    />
                </label>
                <label>
                    Admin
                    <input
                        type='radio'
                        value={AuthLevel.ADMIN}
                        checked={authLevel === AuthLevel.ADMIN}
                        onChange={e => setAuthLevel(parseInt(e.target.value) as AuthLevel)}
                    />
                </label>
                <div>
                    {status}
                </div>
                <button className="manage-button" onClick={() => submitUser()}>
                    Add New User
                </button>
            </div>
        </div>
    );
}

export default AddUser
