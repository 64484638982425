import axios, { AxiosResponse } from "axios";
import { AuthApiType, IAuth} from "./types";

const baseUrl: string = "https://time.bruinlife.com/api";

export const getUser = async (
    username: string
): Promise<AxiosResponse<AuthApiType>> => {
    try {
        const queriedUser: AxiosResponse<AuthApiType> = await axios.get(
            `${baseUrl}/get-user?username=${username}`
        );
        return queriedUser;
    } catch (error) {
        throw error;
    }
};

export const getUsers = async (): Promise<
  AxiosResponse<AuthApiType>
> => {
    try {
        const users: AxiosResponse<AuthApiType> = await axios.get(
            baseUrl + "/get-users"
        );
        return users
    } catch (error) {
        throw error
    }
}

export const addUser = async (
    formData: IAuth
): Promise<AxiosResponse<AuthApiType>> => {
    try {
        const user: Pick<
            IAuth,
            | "username"
            | "password"
            | "authLevel"
        > = {
            username: formData.username,
            password: formData.password,
            authLevel: formData.authLevel
        };
        const addedUser: AxiosResponse<AuthApiType> = await axios.post(
            baseUrl + "/add-user",
            user
        );
        return addedUser;
    } catch (error) {
        throw error
    }
};
