import { useEffect, useState } from "react";
import { getEmployees } from "../API_employee";
import type { IEmployee } from "../types";
import EditEmployeeModal from "./edit_employee_modal";
import AddEmployeeModal from "./add_employee_modal";
import Filter from "./filter";

const AdminPage = () => {
    const [employees, setEmployees] = useState<IEmployee[]>([]);
    const [filtered_employees, setFilteredEmployees] = useState<IEmployee[]>([]);
    const [selected_employee, setSelectedEmployee] = useState<IEmployee>(Object);
    const [show_edit_modal, setShowEditModal] = useState<boolean>(false);
    const [show_add_modal, setShowAddModal] = useState<boolean>(false);

    useEffect(() => {
        if (!show_edit_modal && !show_add_modal) {
            getEmployees().then((response) => {
                setEmployees(response.data.employees);
                setFilteredEmployees(response.data.employees)
            });
        }
    }, [show_edit_modal, show_add_modal]);

    const handleEditEmployee = (employee: IEmployee) => {
        setSelectedEmployee(employee);
        setShowEditModal(true);
    }

    const handleAddEmployee = () => {
        setShowAddModal(true);
    }

    const listEmployees = () => {
        return filtered_employees.map((employee, index) => {
            const employee_name = `${employee.firstName} ${employee.lastName}`
            return (
                <tr className="table-row" key={index}>
                    <td className="table-data">{employee_name}</td>
                    <td className="table-data">{employee.department}</td>
                    <td className="table-data">{employee.weeklyHours.toFixed(3)}</td>
                    <td className="table-data">{employee.quarterHours.toFixed(3)}</td>
                    <td className="table-data">{employee.totalHours.toFixed(3)}</td>
                    <td className="table-data">
                        <button className="manage-button" onClick={() => handleEditEmployee(employee)}>
                            {`Edit`}
                        </button>
                    </td>
                </tr>
            )
        })
    }

    return (
        <div className="timesheet-container">
            {/* <AddEmployee saveEmployee={handleAddEmployee}/> */}
            <p className="timesheet-title">Manage Users</p>
            <Filter
                stored_employees={employees}
                updateEmployees={setFilteredEmployees}
                filter_fields={{
                    department: true,
                    name: true,
                }}
            />
            <button className="manage-button" onClick={() => handleAddEmployee()}>
                Add User Entry
            </button>
            <div className="timesheet">
                <table className="time-table">
                    <thead>
                        <tr>
                        <th className="table-headers">Team Member</th>
                        <th className="table-headers">Department</th>
                        <th className="table-headers">Weekly Hours</th>
                        <th className="table-headers">Quarterly Hours</th>
                        <th className="table-headers">Total Hours</th>
                        <th className="table-headers">Manage User</th>
                        </tr>
                    </thead>
                    <tbody>{listEmployees()}</tbody>
                </table>
            </div>
            {
                show_edit_modal &&
                <EditEmployeeModal
                    setModal={setShowEditModal}
                    employee={selected_employee}
                />
            }
            {
                show_add_modal &&
                <AddEmployeeModal
                    setModal={setShowAddModal}
                />
            }
        </div>
    );
  };

  export default AdminPage;
