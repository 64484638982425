import { useState } from "react";
import { deleteShift, editShift } from "../API_shifts";
import { IShift } from "../types";

interface EditModalProps {
    setModal: Function;
    shift: IShift
}

const EditShiftModal = (props: EditModalProps) => {
    const [shift, setShift] = useState<IShift>({...props.shift});

    const confirmEdit = (): void => {
        const editedShift: IShift = {...shift};
        editedShift.clockInTime = new Date(editedShift.clockInTime);
        editedShift.clockOutTime = new Date(editedShift.clockOutTime);
        editedShift.shiftHours = (editedShift.clockOutTime.getTime() - editedShift.clockInTime.getTime()) / (60 * 60 * 1000);
        editShift(editedShift).then(() => props.setModal(false));
    };

    const confirmDelete = () => {
        deleteShift(shift).then(() => props.setModal(false));
    }

    const handleChange = (e: React.FormEvent<HTMLInputElement>, value: any) => {
        setShift({
            ...shift,
            [e.currentTarget.id]: value
        })
    }

    const editForm = () => {
        return (
            <div>
            <div className="modal-field">
                <label>Clock-In Time</label>
                <input
                    type="datetime-local"
                    id="clockInTime"
                    onChange={e => handleChange(e, e.target.value)}
                />
            </div>
            <div className="modal-field">
                <label>Clock-Out Time</label>
                <input
                    type="datetime-local"
                    id="clockOutTime"
                    onChange={e => handleChange(e, e.target.value)}
                />
            </div>
                <div>
                    <label>Auto-Clocked Out</label>
                    <input
                        type='checkbox'
                        id='autoClockedOut'
                        onChange={e => handleChange(e, e.target.checked)}
                        defaultChecked={shift.autoClockedOut}
                    />
                </div>
                <button className="delete-button" onClick={() => confirmDelete()}>
                    {`Remove Shift`}
                </button>
            </div>
        )
    }

    return (
        <div className="modal-background">
            <div className="clock-modal">
                <div className="close-modal">
                    <button onClick={() => props.setModal(false)}>
                        X
                    </button>
                </div>
                <div className="modal-title">
                    <h1>{`Editing Shift...`}</h1>
                </div>
                <div className="modal-body">
                    {editForm()}
                </div>
                <div className="modal-footer">
                    <button
                        className="modal-cancel"
                        onClick={() => {
                            props.setModal(false);
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        className="modal-confirm"
                        onClick={() => {
                            confirmEdit();
                        }}
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    );
}

export default EditShiftModal;
