import React, { useEffect, useState } from "react";
import { getShift, getShifts } from "../API_shifts";
import { IShift } from "../types";
import AddShiftModal from "./add_shift_modal";
import EditShiftModal from "./edit_shift_modal";

const Shifts = () => {
    const [shifts, setShifts] = useState<IShift[]>([]);
    const [shownShifts, setShownShifts] = useState<IShift[]>([]);
    const [selectedShift, setSelectedShift] = useState<IShift>(Object);
    const [filteredName, setFilteredName] = useState<string>('');
    const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
    const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
    const [showAutoclocked, setShowAutoclocked] = useState<boolean>(false);

    useEffect(() => {
        if (!editModalOpen && !addModalOpen) {
            // reset filtered email
            setFilteredName('');

            getShifts().then(res => {
                setShifts(res.data.shifts ?? []);
                setShownShifts(res.data.shifts ?? []);
            });
        }
    }, [editModalOpen, addModalOpen]);

    function handleEditShift(shift: IShift) {
        setSelectedShift(shift);
        setEditModalOpen(true);
    }

    function handleAddShift() {
        setAddModalOpen(true);
    }

    function listShifts(shifts: IShift[], showAutoclocked: boolean) {
        return shifts.map((shift, index) => {
            if (showAutoclocked && !shift.autoClockedOut) {
                return <React.Fragment key={index} />
            }
            const clockInTime = new Date(shift.clockInTime).toLocaleString('en-US');
            const clockOutTime = new Date(shift.clockOutTime).toLocaleString('en-US')
            // rounds to the nearest 0.5 hour
            const hours = shift.shiftHours.toFixed(3);

            const dataClassName = shift.autoClockedOut
                ? 'table-data-auto-clocked'
                : 'table-data'

            const name = `${shift.lastName}, ${shift.firstName}`;

            return (
                <tr className="table-row" key={index}>
                    <td className={dataClassName}>{name}</td>
                    <td className={dataClassName}>{clockInTime}</td>
                    <td className={dataClassName}>{clockOutTime}</td>
                    <td className={dataClassName}>{hours}</td>
                    <td className={dataClassName}>
                        <button
                            className="manage-button"
                            onClick={() => handleEditShift(shift)}
                        >
                            Edit
                        </button>
                    </td>
                </tr>
            )
        })
    }

    const uniqueNames = Array.from(new Set(shifts.map(shift => `${shift.lastName}, ${shift.firstName}`)));
    function handleSelectFilterName(e: React.ChangeEvent<HTMLSelectElement>) {
        setFilteredName(e.target.value);
    }
    useEffect(() => {
        if (filteredName === '') {
            setShownShifts(shifts);
            return;
        }
        const [lastName, firstName] = filteredName.split(', ');
        getShift(firstName, lastName).then(res => {
            setShownShifts(res.data.shift ?? []);
        });
    }, [filteredName, shifts]);

    function handleCheckShowAutoclock(e: React.ChangeEvent<HTMLInputElement>) {
        setShowAutoclocked(e.target.checked);
    }

    return (
        <div className='timesheet-container'>
            <p className='timesheet-title'>
                Shifts
            </p>
            <button className="manage-button" onClick={handleAddShift}>
                Add Shift
            </button>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <select value={filteredName} onChange={handleSelectFilterName}>
                    <option value='' disabled>Filter by Email</option>
                    {
                        uniqueNames.map((name, idx) => (
                            <option value={name} key={idx}>{name}</option>
                        ))
                    }
                    <option value=''>None</option>
                </select>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: '1rem' }}>
                <label style={{ color: 'white' }}>
                    Only Show Autoclocked
                    <input
                        type='checkbox'
                        checked={showAutoclocked}
                        onChange={handleCheckShowAutoclock}
                    />
                </label>
            </div>
            <div className="timesheet">
                <table className="time-table">
                    <thead>
                        <tr>
                            <th className='table-headers'>Name</th>
                            <th className='table-headers'>Clock In Time</th>
                            <th className='table-headers'>Clock Out Time</th>
                            <th className='table-headers'>Shift Hours</th>
                            <th className='table-headers'>Edit</th>
                        </tr>
                    </thead>
                    <tbody>
                        { listShifts(shownShifts, showAutoclocked) }
                    </tbody>
                </table>
            </div>
            {
                editModalOpen
                ? <EditShiftModal setModal={setEditModalOpen} shift={selectedShift} />
                : <></>
            }
            {
                addModalOpen
                ? <AddShiftModal setModal={setAddModalOpen} />
                : <></>
            }
        </div>
    )
}

export default Shifts;
