import axios, { AxiosResponse } from "axios";
import { DepartmentApiType, IDepartment} from "./types";

const baseUrl: string = "https://time.bruinlife.com/api";

export const getDepartments = async (): Promise<
  AxiosResponse<DepartmentApiType>
> => {
    try {
        const departments: AxiosResponse<DepartmentApiType> = await axios.get(
            baseUrl + "/get-departments"
        );
        return departments
    } catch (error) {
        console.log(error)
        throw new Error()
    }
}

export const addDepartment = async (
    formData: IDepartment
): Promise<AxiosResponse<DepartmentApiType>> => {
    try {
        const department: Pick<
            IDepartment,
            | "name"
        > = {
            name: formData.name
        };
        const addedDepartment: AxiosResponse<DepartmentApiType> = await axios.post(
            baseUrl + "/add-department",
            department
        );
        return addedDepartment;
    } catch (error) {
        throw new Error();
    }
};

export const editDepartment = async (
    originalFormData: IDepartment,
    formData: IDepartment
): Promise<AxiosResponse<DepartmentApiType>> => {
    try {
        const departmentUpdate: Pick<
            IDepartment,
            | "name"
        > = {
            name: formData.name
        };

        const updatedDepartment: AxiosResponse<DepartmentApiType> = await axios.put(
            `${baseUrl}/edit-department?name=${originalFormData.name}&new_name=${formData.name}`,
            departmentUpdate
        );
        return updatedDepartment;
    } catch (error) {
        throw new Error();
    }
};

export const deleteDepartment = async (
  name: string
): Promise<AxiosResponse<DepartmentApiType>> => {
    try {
        const deletedDepartment: AxiosResponse<DepartmentApiType> = await axios.delete(
            baseUrl + "/delete-department",
            { data: {name: name} }
        );
        return deletedDepartment;
    } catch (error) {
        throw new Error();
    }
}
