import { useState, useEffect } from "react"
import { IEmployee, IQuarterly, IShift } from "../types";
import { getEmployees } from "../API_employee";
import { getShifts } from "../API_shifts";
import { getEmployeeHours } from "../timing_utils";
import { getAllQuarterDates } from "../API_quarters";

enum RankingMode {
    TOTAL = "Total Hours",
    QUARTERLY = "Quarterly Hours",
    WEEKLY = "Weekly Hours",
}

const strToMode = (mode_string: string): RankingMode => {
    // defaults to total
    switch (mode_string) {
        case "Quarterly Hours": return RankingMode.QUARTERLY;
        case "Weekly Hours": return RankingMode.WEEKLY;
        default: return RankingMode.TOTAL;
    }
}

interface DepartmentHoursType {
    department: string;
    totalHours: number;
    quarterlyHours: number;
    weeklyHours: number;
    numEmployees: number;
}

const DepartmentHours = () => {
    const [employees, setEmployees] = useState<IEmployee[]>([]);
    useEffect(() => {
        let isMounted = true;
        getEmployees().then((response) => {
            if (!isMounted) {
                return;
            }
            setEmployees(response.data.employees);
        });
        return () => { isMounted = false; };
    }, []);

    const [shifts, setShifts] = useState<IShift[]>([]);
    useEffect(() => {
        let isMounted = true;
        getShifts().then((response) => {
            if (!isMounted) {
                return;
            }
            setShifts(response.data.shifts ?? []);
        });
        return () => { isMounted = false; };
    }, []);

    const [quarters, setQuarters] = useState<IQuarterly[]>([]);
    useEffect(() => {
        let isMounted = true;
        getAllQuarterDates().then((response) => {
            if (!isMounted) {
                return;
            }
            setQuarters(response.data ?? []);
        });
        return () => { isMounted = false; };
    }, []);

    const [departmentHours, setDepartmentHours] = useState<DepartmentHoursType[]>([]);
    useEffect(() => {
        if (employees.length === 0) {
            return;
        }
        const employeeHours = getEmployeeHours(employees, shifts, quarters);
        const calcDepartmentHours: { [department: string]: DepartmentHoursType } = {};

        for (const employee of employeeHours) {
            const department = employee.department.trim();
            if (Object.keys(calcDepartmentHours).includes(department)) {
                calcDepartmentHours[department].totalHours += employee.totalHours;
                calcDepartmentHours[department].quarterlyHours += employee.quarterlyHours;
                calcDepartmentHours[department].weeklyHours += employee.weeklyHours;
                calcDepartmentHours[department].numEmployees += 1;
            } else {
                calcDepartmentHours[department] = {
                    department: department,
                    totalHours: employee.totalHours,
                    quarterlyHours: employee.quarterlyHours,
                    weeklyHours: employee.weeklyHours,
                    numEmployees: 1
                };
            }
        }

        // calculate average hours
        for (const departmentHours of Object.values(calcDepartmentHours)) {
            const numEmployees = departmentHours.numEmployees;
            departmentHours.totalHours /= numEmployees;
            departmentHours.quarterlyHours /= numEmployees;
            departmentHours.weeklyHours /= numEmployees;
        }

        const calcDepartmentHoursArr: DepartmentHoursType[] = Object.values(calcDepartmentHours);
        calcDepartmentHoursArr.sort((a, b) => {
            if (a.totalHours !== b.totalHours) {
                return b.totalHours - a.totalHours;
            }

            // then alphabetically by department
            return a.department.localeCompare(b.department);
        })
        setDepartmentHours(calcDepartmentHoursArr);
    }, [employees, quarters, shifts]);

    const [rankingMode, setRankingMode] = useState<RankingMode>(RankingMode.TOTAL);
    useEffect(() => {
        let rankKey: "totalHours" | "quarterlyHours" | "weeklyHours" = "totalHours";
        switch (rankingMode) {
            case RankingMode.QUARTERLY: rankKey = "quarterlyHours"; break;
            case RankingMode.WEEKLY: rankKey = "weeklyHours"; break;
            default: rankKey = "totalHours"; break;
        }

        setDepartmentHours(curr => {
            const res = [...curr];
            res.sort((a, b) => {
                if (a[rankKey] !== b[rankKey]) {
                    return b[rankKey] - a[rankKey];
                }

                // then alphabetically by department
                return a.department.localeCompare(b.department);
            });
            return res;
        });

    }, [rankingMode]);

    function listDepartments() {
        if (departmentHours.length === 0) {
            return <></>
        }

        let rankKey: "totalHours" | "quarterlyHours" | "weeklyHours" = "totalHours";
        switch (rankingMode) {
            case RankingMode.QUARTERLY: rankKey = "quarterlyHours"; break;
            case RankingMode.WEEKLY: rankKey = "weeklyHours"; break;
            default: rankKey = "totalHours"; break;
        }

        return departmentHours.map((department, index) => {
            return (
                <tr className="table-row" key={index}>
                    <td className="table-data">
                        {department.department}
                    </td>
                    <td className="table-data">
                        {department[rankKey].toFixed(3)}
                    </td>
                </tr>
            )
        })
    }

    return (
        <div className="timesheet-container">
            <p className="timesheet-title">Top Average Hours by Department</p>
            <select
                value={rankingMode}
                onChange={e => setRankingMode(strToMode(e.target.value))}
            >
                <option value={RankingMode.TOTAL}>{RankingMode.TOTAL}</option>
                <option value={RankingMode.QUARTERLY}>{RankingMode.QUARTERLY}</option>
                <option value={RankingMode.WEEKLY}>{RankingMode.WEEKLY}</option>
            </select>
            <div className="timesheet">
                <table className="time-table">
                    <thead>
                        <tr>
                            <th className="table-headers">Department</th>
                            <th className="table-headers">{rankingMode}</th>
                        </tr>
                    </thead>
                    <tbody>{listDepartments()}</tbody>
                </table>
            </div>
        </div>
    )
}

export default DepartmentHours;
