import { useState } from "react";
import { IDepartment } from "../types";
import { addDepartment } from "../API_department";

interface AddModalProps {
    setModal: Function;
}

const AddDepartmentModal = (props: AddModalProps) => {
    const [department_data, setDepartmentData] = useState<IDepartment>(Object);

    const confirmAdd = (): void => {
        addDepartment(department_data)
            .then(() => props.setModal(false))
            .catch((error) => console.log(error));
    };

    const isFormValid = () => {
        // Check if any of the fields is empty
        return department_data.name;
    };

    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
        setDepartmentData({
            ...department_data,
            [e.currentTarget.id]: e.currentTarget.value
        })
        // console.log(employee_data)
    }

    const addForm = () => {
        return (
            <div>
                <div>
                    <label>Department Name</label>
                    <input
                        type="text"
                        id="name"
                        onChange={handleChange}
                    />
                </div>
            </div>
        )
    }

    return (
        <div className="modal-background">
            <div className="clock-modal">
                <div className="close-modal">
                    <button onClick={() => props.setModal(false)}>
                        X
                    </button>
                </div>
                <div className="modal-title">
                    <h1>Add a New Department</h1>
                </div>
                <div className="modal-body">
                    {addForm()}
                </div>
                <div className="modal-footer">
                    <button
                        className="modal-cancel"
                        onClick={() => {
                            props.setModal(false);
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        className="modal-confirm"
                        onClick={() => {
                            if (isFormValid()) {
                                confirmAdd();
                            } else {
                                console.log("Please fill out all fields before confirming.");
                            }
                        }}
                        style={{ backgroundColor: isFormValid() ? "" : "gray", 
                                cursor: isFormValid() ? "pointer" : "not-allowed",}}
                        disabled={!isFormValid()}
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    );
}

export default AddDepartmentModal