import { useEffect, useState } from "react";
import { getDepartments } from "../API_department";
import { IDepartment } from "../types";
import EditDepartmentModal from "./edit_department_modal";
import AddDepartmentModal from "./add_department_modal";

const ManageDepartments = () => {
    const [departments, setDepartments] = useState<IDepartment[]>([]);
    const [selected_department, setSelectedDepartment] = useState<IDepartment>(Object);
    const [show_add_modal, setShowAddModal] = useState<boolean>(false);
    const [show_edit_modal, setShowEditModal] = useState<boolean>(false);

    useEffect(() => {
        if (!show_add_modal && !show_add_modal) {
            getDepartments().then((response) => {
                setDepartments(response.data.departments)
            })
        }
    }, [show_add_modal, show_edit_modal])

    const handleAddDepartment = () => {
        setShowAddModal(true);
    }

    const handleEditDepartment = (department: IDepartment) => {
        setSelectedDepartment(department);
        setShowEditModal(true);
    }

    const listEmployees = () => {
        return departments.map((department, index) => {
            return (
                <tr className="table-row" key={index}>
                    <td className="table-data">{department.name}</td>
                    <td className="table-data">
                        <button
                            className="manage-button"
                            onClick={() => handleEditDepartment(department)}
                        >
                            {`Edit`}
                        </button>
                    </td>
                </tr>
            )
        })
    }

    return (
        <div className="timesheet-container">
            <p className="timesheet-title">Manage Departments</p>
            <button className="manage-button" onClick={() => handleAddDepartment()}>
                Add Department
            </button>
            <div className="timesheet">
                <table className="time-table">
                    <thead>
                        <tr>
                            <th className="table-headers">Department</th>
                            <th className="table-headers">Edit Department</th>
                        </tr>
                    </thead>
                    <tbody>{listEmployees()}</tbody>
                </table>
            </div>
            {
                show_edit_modal &&
                <EditDepartmentModal
                    setModal={setShowEditModal}
                    department={selected_department}
                />
            }
            {
                show_add_modal &&
                <AddDepartmentModal
                    setModal={setShowAddModal}
                />
            }
        </div>
    )
}

export default ManageDepartments
