import { useState } from "react";
import { addShift } from "../API_shifts";
import { IShift } from "../types";

interface AddModalProps {
    setModal: Function;
}

function AddShiftModal(props: AddModalProps) {

    const { setModal } = props;
    const [newShift, setNewShift] = useState<IShift>(Object);

    const handleChange = (
        e: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLSelectElement>
    ) => {
        setNewShift({
            ...newShift,
            [e.currentTarget.id]: e.currentTarget.value
        })
    }

    function confirmAdd() {
        if (!newShift.firstName || !newShift.lastName || !newShift.clockInTime || !newShift.clockOutTime) {
            alert('Please fill in all fields');
            return;
        }
        const addingShift: IShift = { ...newShift };
        addingShift.clockInTime = new Date(newShift.clockInTime);
        addingShift.clockOutTime = new Date(newShift.clockOutTime);
        addingShift.shiftHours = (addingShift.clockOutTime.getTime() - addingShift.clockInTime.getTime()) / (60 * 60 * 1000);
        addingShift.autoClockedOut = false;

        addShift(addingShift).then(() => setModal(false)).catch(e => console.log(e)) ;
    }
         
    const addForm = (
        <>
            <div className="modal-field">
                <label>First Name</label>
                <input
                    type="text"
                    id="firstName"
                    onChange={handleChange}
                />
            </div>
            <div className="modal-field">
                <label>Last Name</label>
                <input
                    type="text"
                    id="lastName"
                    onChange={handleChange}
                />
            </div>
            <div className="modal-field">
                <label>Clock-In Time</label>
                <input
                    type="datetime-local"
                    id="clockInTime"
                    onChange={handleChange}
                />
            </div>
            <div className="modal-field">
                <label>Clock-Out Time</label>
                <input
                    type="datetime-local"
                    id="clockOutTime"
                    onChange={handleChange}
                />
            </div>
        </>
    )

    return (
        <div className="modal-background">
            <div className="clock-modal">
                <div className="close-modal">
                    <button onClick={() => props.setModal(false)}>
                        x
                    </button>
                </div>
                <div className="modal-title">
                    <h1>{`Adding Shift...`}</h1>
                </div>
                <div className="modal-body">
                    { addForm }
                </div>
                <div className="modal-footer">
                    <button
                        className="modal-cancel"
                        onClick={() => {
                            props.setModal(false);
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        className="modal-confirm"
                        onClick={() => confirmAdd()}
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    );
}

export default AddShiftModal;
