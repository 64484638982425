import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { AuthLevel } from "./login";

interface NavbarProps {
    authLevel: AuthLevel;
    onLogout: () => void;
}

const Navbar: React.FC<NavbarProps> = (props) => {
    const [isNavActive, setIsNavActive] = useState(false);

    const toggleNav = () => {
        setIsNavActive(!isNavActive);
    };

    useEffect(() => {
        window.onload = () => {
            const toggleNav = document.getElementsByClassName("toggle-nav")[0];
            const navbarLinks = document.getElementsByClassName("navbar-links")[0];
            const navButton = document.getElementsByClassName("nav-button")[0];
            toggleNav.addEventListener("click", () => {
                navButton.classList.toggle("active");
                navbarLinks.classList.toggle("active");
            });
        };

        // Clean up the event listener when the component unmounts
        return () => {
            window.onload = null;
        };
    }, []);

    const LoginButton = () => {
        if (props.authLevel) {
            return (
                <button type="button" className="nav-button" onClick={props.onLogout}>
                    Logout
                </button>
            );
        } else {
            return (
                <Link className="nav-button-link" to="/login">
                    Login
                </Link>
            );
        }
    };

    const adminLinks = () => {
        return (
            <>
                <li>
                    <Link className="nav-link" to="/admin_page">
                        Manage Users
                    </Link>
                </li>
                <li>
                    <Link className="nav-link" to="/shifts">
                        Shifts
                    </Link>
                </li>
                <li>
                    <Link className="nav-link" to="/manage-departments">
                        Manage Departments
                    </Link>
                </li>
                <li>
                    <Link className="nav-link" to="/add-user-page">
                        Add Account
                    </Link>
                </li>
                <li>
                    <Link className="nav-link" to="/edit-quarter">
                        Edit Quarters
                    </Link>
                </li>
            </>
        )
    }

    return (
        <nav className={`navbar ${isNavActive ? "active" : ""}`}>
            <div className="brand-title">Student Media BruinLife</div>
            <a href="#nav" className="toggle-nav" onClick={toggleNav}>
                <span className="bar"></span>
                <span className="bar"></span>
                <span className="bar"></span>
            </a>
            <div className={`navbar-links ${isNavActive ? "active" : ""}`}>
                <ul>
                    {props.authLevel && (
                        <>
                            <li>
                                <Link className="nav-link" to="/timesheet">
                                    Timesheet
                                </Link>
                            </li>
                            <li>
                                <Link className="nav-link" to="/top-hours">
                                    Individual Hours
                                </Link>
                            </li>
                            <li>
                                <Link className="nav-link" to="/department-hours">
                                    Department Hours
                                </Link>
                            </li>
                        </>
                    )}
                    {props.authLevel === AuthLevel.ADMIN && adminLinks()}
                </ul>
            </div>
            {LoginButton()}
        </nav>
    );
};

export default Navbar;
