import "../styles/navbar.css";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import { AuthLevel } from "./login";

interface NavbarProps {
    authLevel: AuthLevel;
    onLogout: () => void;
}

const ADMIN_LINKS = {
    "/admin_page": "Manage Users",
    "/shifts": "Shifts",
    "/manage-departments": "Manage Departments",
    "/add-user-page": "Add Account",
    "/edit-quarter": "Edit Quarters",
}

const GENERAL_LINKS = {
    "/timesheet": "Timesheet",
    "/top-hours": "Individual Hours",
    "/department-hours": "Department Hours",
}

const Navbar: React.FC<NavbarProps> = (props) => {
    const [hamburger, setHamburger] = useState<boolean>(false);
    function toggleHamburger() {
        setHamburger(!hamburger);
    }

    const LoginButton = () => {
        if (props.authLevel) {
            return (
                <button type="button" className="nav-button" onClick={props.onLogout}>
                    Logout
                </button>
            );
        } else {
            return (
                <Link className="nav-button-link" to="/login">
                    Login
                </Link>
            );
        }
    }

    function createLinks() {
        if (!props.authLevel) {
            return <></>
        }

        const links = []
        for (const [relpath, title] of Object.entries(GENERAL_LINKS)) {
            links.push(
                <li>
                    <Link className="nav-link" to={relpath}>
                        {title}
                    </Link>
                </li>
            )
        }

        if (props.authLevel === AuthLevel.ADMIN) {
            for (const [relpath, title] of Object.entries(ADMIN_LINKS)) {
                links.push(
                    <li>
                        <Link className="nav-link" to={relpath}>
                            {title}
                        </Link>
                    </li>
                )
            }
        }

        return links;
    }

    return (
        <>
            <nav className="navbar">
                <div className="brand-title">Student Media BruinLife</div>
                <div className="navbar-links">
                    <ul>
                        {createLinks()}
                    </ul>
                </div>
                <div className="navbar-hamburger">
                    <button onClick={toggleHamburger}>
                        ☰
                    </button>
                    <div className={`navbar-menu ${hamburger ? "active" : ""}`} onClick={toggleHamburger}>
                        <button className="navbar-close-menu" onClick={toggleHamburger}>X</button>
                        <ul>
                            {createLinks()}
                        </ul>
                    </div>
                </div>
                {LoginButton()}
            </nav>
        </>
    );
};

export default Navbar;
