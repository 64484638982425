import { useState, useEffect } from "react";
import { IDepartment, IEmployee } from "../types";
import { updateEmployee, deleteEmployee } from "../API_employee";
import { getDepartments } from "../API_department";

interface EditModalProps {
    setModal: Function;
    employee: IEmployee
}

const EditEmployeeModal = (props: EditModalProps) => {
    const employee_name = `${props.employee.firstName} ${props.employee.lastName}`;
    const [employee_data, setEmployeeData] = useState<IEmployee>({...props.employee});
    const [all_departments, setAllDepartments] = useState<IDepartment[]>([])

    useEffect(() => {
        getDepartments().then((response) => {
            setAllDepartments(response.data.departments)
        })
    }, [])

    const confirmEdit = (): void => {
        updateEmployee(employee_data)
            .then(() => props.setModal(false))
            .catch((error) => console.log(error));
    };

    const confirmDelete = () => {
        deleteEmployee(employee_data.firstName, employee_data.lastName)
            .then(() => props.setModal(false))
            .catch((error) => console.log(error));
    }

    const getDepartmentOptions = () => {
        const current_department = props.employee.department
        return (
            <select id="department" onChange={e => handleChange(e)}>
                <option value={current_department}>
                    {current_department}
                </option>
                {all_departments
                    .filter(department => department.name !== current_department)
                    .map((department, index) =>
                        <option value={department.name} key={index}>
                            {department.name}
                        </option>
                    )
                }
            </select>
        )
    }

    const handleChange = (
        e: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLSelectElement>
    ) => {
        setEmployeeData({
            ...employee_data,
            [e.currentTarget.id]: e.currentTarget.value
        })
    }

    const editForm = () => {
        return (
            <div>
                <div>
                    <label>Department</label>
                    {getDepartmentOptions()}
                </div>
                <div>
                    <label>Weekly Hours</label>
                    <input
                        type="text"
                        id="weeklyHours"
                        onChange={e => handleChange(e)}
                    />
                </div>
                <div>
                    <label>Quarterly Hours</label>
                    <input
                        type="text"
                        id="quarterHours"
                        onChange={e => handleChange(e)}
                    />
                </div>
                <div>
                    <label>Total Hours</label>
                    <input
                        type="text"
                        id="totalHours"
                        onChange={e => handleChange(e)}
                    />
                </div>
                <button className="delete-button" onClick={() => confirmDelete()}>
                    {`Remove ${employee_name}`}
                </button>
            </div>
        )
    }

    return (
        <div className="modal-background">
            <div className="clock-modal">
                <div className="close-modal">
                    <button onClick={() => props.setModal(false)}>
                        X
                    </button>
                </div>
                <div className="modal-title">
                    <h1>{`Editing: ${employee_name}`}</h1>
                </div>
                <div className="modal-body">
                    {editForm()}
                </div>
                <div className="modal-footer">
                    <button
                        className="modal-cancel"
                        onClick={() => {
                            props.setModal(false);
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        className="modal-confirm"
                        onClick={() => {
                            confirmEdit();
                        }}
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    );
}

export default EditEmployeeModal
