import "../styles/timesheet.css"
import { useEffect, useState } from "react";
import { getEmployees } from "../API_employee";
import type { IEmployee } from "../types";
import ClockModal from "./clock_modal";
import { AuthLevel } from "./login";

type TimesheetProps = {
    authLevel: AuthLevel
}

const Timesheet = (props: TimesheetProps) => {
    const [modal, setModal] = useState(false);
    const [employee_info, setEmployee] = useState<IEmployee>(Object);
    const [stored_employees, setEmployees] = useState<IEmployee[]>([]);
    const [filtered_employees, setFilteredEmployees] = useState<IEmployee[]>([]);
    const [searchName, setSearchName] = useState<string>('');

    const writeableAuthLevels: AuthLevel[] = [AuthLevel.ADMIN, AuthLevel.READ_WRITE];

    useEffect(() => {
        getEmployees().then((response) => {
            setEmployees(response.data.employees);
            setFilteredEmployees(response.data.employees)
        }
        )
    }, [employee_info]);

    function handleClick(employee_info: IEmployee): void {
        setModal(true);
        setEmployee(employee_info);
    }

    const listEmployees = () => {
        let employee_list = [];
        const sorted_employees = [];
        for (const employee of filtered_employees) {
            const employee_copy = { ...employee };
            sorted_employees.push(employee_copy)
        }
        sorted_employees.sort((a, b) => {
            const lhs = a.lastName.toLowerCase();
            const rhs = b.lastName.toLowerCase();
            if (lhs < rhs) { return -1; }
            if (lhs > rhs) { return 1; }
            return 0;
        })

        for (let i = 0; i < sorted_employees.length; i++) {
            let employee = sorted_employees[i];
            let employee_name = `${employee.lastName}, ${employee.firstName}`;

            const autoClocked = employee.isAutoClocked ?? false;
            const tableDataClassName = autoClocked ? 'table-data-auto-clocked' : 'table-data';
            const clockButtonClassName = employee.isClockedIn ? 'table-clockOut' : 'table-clockIn';
            const clockButtonLabel = employee.isClockedIn ? 'Clock Out' : 'Clock In';

            employee_list.push(
                <tr className="table-row" key={i}>
                    <td className={tableDataClassName}>{employee_name}</td>
                    <td className={tableDataClassName}>{employee.department}</td>
                    {writeableAuthLevels.includes(props.authLevel) &&
                        <td>
                            <button
                                className={clockButtonClassName}
                                onClick={() => handleClick(employee)}
                            >
                                {clockButtonLabel}
                            </button>
                        </td>
                    }
                </tr>
            );
        }

        return employee_list;
    };

    useEffect(() => {
        setFilteredEmployees(stored_employees.filter(
            (e: IEmployee) => {
                return e.lastName?.toLowerCase().startsWith(searchName.toLowerCase());
            }
        ));
    }, [searchName, stored_employees]);

    return (
        <div className="timesheet-container">
            <p className="timesheet-title">Timesheet</p>
            <input
                type='text'
                onChange={e => setSearchName(e.target.value)}
                style={{
                    marginBottom: '16px',
                    padding: '4px'
                }}
                placeholder='Enter Last Name...'
            />
            <div className="timesheet">
                <table className="time-table">
                    <thead>
                        <tr>
                            <th className="table-headers">Team Member</th>
                            <th className="table-headers">Department</th>
                            {writeableAuthLevels.includes(props.authLevel) &&
                                <th className="table-headers">Clock In/Out</th>
                            }
                        </tr>
                    </thead>
                    <tbody>{listEmployees()}</tbody>
                </table>
            </div>
            {modal && <ClockModal setModal={setModal} employee={employee_info} setEmployee={setEmployee} />}
        </div>
    );
};

export default Timesheet;
