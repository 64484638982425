import axios, { AxiosResponse } from "axios";
import { ApiDataType, IQuarterly } from "./types";

const baseUrl: string = "https://time.bruinlife.com/api";

export const addQuarterDate = async (
  formData: IQuarterly
): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const quarter: Pick<IQuarterly, "quarterDate" | "quarter" | "year"> = {
      quarterDate: formData.quarterDate,
      quarter: formData.quarter,
      year: formData.year,
    };
    const saveQuarter: AxiosResponse<ApiDataType> = await axios.post(
      baseUrl + "/add-quarter-dates",
      quarter
    );
    return saveQuarter;
  } catch (error) {
    throw new Error();
  }
};

export const updateQuarterDate = async (
  formData: IQuarterly
): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const quarter: Pick<IQuarterly, "quarterDate" | "quarter" | "year"> = {
      quarterDate: formData.quarterDate,
      quarter: formData.quarter,
      year: formData.year,
    };
    const saveQuarter: AxiosResponse<ApiDataType> = await axios.put(
      baseUrl + "/update-quarter-dates",
      quarter
    );
    return saveQuarter;
  } catch (error) {
    throw new Error();
  }
};

export const getAllQuarterDates = async (): Promise<
  AxiosResponse<IQuarterly[]>
> => {
  try {
    const quarterDates: AxiosResponse<IQuarterly[]> = await axios.get(
      baseUrl + "/get-quarter-dates"
    );
    return quarterDates;
  } catch (error) {
    throw new Error();
  }
};

export async function deleteQuarterDate(quarter: string, year: number): Promise<
  AxiosResponse<IQuarterly>
> {
  const deletedQuarter: AxiosResponse<IQuarterly> = await axios.delete(
    baseUrl + "/delete-quarter-dates",
    { 
      data: {
        quarter: quarter,
        year: year
      }
    }
  );
  return deletedQuarter;
}
