import { useState } from "react";
import { deleteQuarterDate, updateQuarterDate } from "../API_quarters";
import { IQuarterly } from "../types";

interface EditModalProps {
    setModal: Function;
    quarter: IQuarterly
}

const EditQuarterModal = (props: EditModalProps) => {
    const [date, setDate] = useState<Date>(new Date(props.quarter.quarterDate));

    function dateAsValue(date: Date): string {
        // because midnight times get pushed 1 day back
        console.log(date);
        date.setUTCHours(8);
        let year = date.getFullYear().toString();
        let month = (date.getMonth() + 1).toString(); // month indexes from 0
        let day = date.getDate().toString();

        if (month.length < 2) {
            month = '0' + month;
        } 
        if (day.length < 2) {
            day = '0' + day;
        }

        return `${year}-${month}-${day}`;
    }

    const confirmEdit = (): void => {
        const updatedQuarter = {
            ...props.quarter,
            quarterDate: date,
        };
        updateQuarterDate(updatedQuarter).then(() => {
            props.setModal(false);
        })
    };

    function confirmDelete() {
        deleteQuarterDate(props.quarter.quarter, props.quarter.year).then(() => {
            props.setModal(false);
        })
    }

    const editForm = () => {
        return (
            <div>
                <div className="modal-field">
                    <label>Start Date</label>
                    <input
                        type="date"
                        value={dateAsValue(date)}
                        onChange={e => setDate(new Date(e.target.value))}
                    />
                </div>
                <button className="delete-button" onClick={() => confirmDelete()}>
                    {`Remove ${props.quarter.quarter} ${props.quarter.year}`}
                </button>
            </div>
        )
    }

    return (
        <div className="modal-background">
            <div className="clock-modal">
                <div className="close-modal">
                    <button onClick={() => props.setModal(false)}>
                        X
                    </button>
                </div>
                <div className="modal-title">
                    <h1>{`Editing Start Date for ${props.quarter.quarter} ${props.quarter.year}`}</h1>
                </div>
                <div className="modal-body">
                    {editForm()}
                </div>
                <div className="modal-footer">
                    <button
                        className="modal-cancel"
                        onClick={() => {
                            props.setModal(false);
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        className="modal-confirm"
                        onClick={() => {
                            confirmEdit();
                        }}
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    );
}

export default EditQuarterModal;
