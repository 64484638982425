import "../styles/login.css";
import { useState } from "react";
import { getUser } from "../API_auth";
import { useNavigate } from "react-router-dom";

interface LoginProps {
  // auth token
  setToken: Function;
}

export enum AuthLevel {
  NONE = 0,
  READ_ONLY = 1,
  READ_WRITE = 2,
  ADMIN = 3,
}

const Login: React.FC<LoginProps> = ({ setToken }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const getUserAuthLevel = async (): Promise<AuthLevel> => {
    try {
      const response = await getUser(username)
      const user = response.data.user
      if (!user) {
        return AuthLevel.NONE
      }
      const expected_password = user.password
      if (password !== expected_password) {
        return AuthLevel.NONE
      }
      return user.authLevel;
    }
    catch (err) {
      return AuthLevel.NONE
    }
  }

  async function onSubmit(e: any) {
    e.preventDefault();

    /*
    Logins:
    Username    Password    AuthLevel
    admin       a           admin
    clock       clock       read/write
    guest       guest       read only
    */

    const authLevel: AuthLevel = await getUserAuthLevel()
    if (authLevel === AuthLevel.NONE) {
      alert("Invalid Login Credentials. Please try again.");
      return;
    } else {
      setToken(authLevel);
      navigate("/");
    }
  }

  async function onEnterPress(e: any) {
    if (e.key !== 'Enter') {
      return;
    }
    onSubmit(e);
  }

  return (
    <div id="login">
      <div className="logo-panel">
        <h3 className="brand">UCLA</h3>
      </div>

      <div className="login-panel">
        <div className="login-container">
          <h2>Bruinlife Timesheet</h2>

          <div className="form-field">
            <label htmlFor="">Username</label>
            <input
              type="text"
              onChange={(e) => setUsername(e.target.value)}
              onKeyDown={e => onEnterPress(e)}
            />
          </div>

          <div className="form-field">
            <label htmlFor="">Password</label>
            <input
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={e => onEnterPress(e)}
            />
          </div>

          <button onClick={(e) => onSubmit(e)} className="login-submit">
            Sign In
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
